import React from "react";
import {TextL, TypicalHeader} from "@mryaros/mryaros-design-system";
import BulatLogo from "../../static/logo.png";
import {IconPhone2} from "./icons/Icons";

export const ApplicationHeader = React.memo(function ApplicationHeader() {

    const headerLinks = (<>
        <a href="/#about">О КОМПАНИИ</a>
        <a href="/#services" className="link-block">УСЛУГИ</a>
        <a href="/#contacts" className="link-block">КОНТАКТЫ</a>
    </>);

    const additionalBlock = (
        <div className="flex-row"><IconPhone2 className="svg-icon margin-r-16 header"/><TextL className="margin-no"><b>+7 (342) 277-57-75</b></TextL></div>
    );
    return <TypicalHeader logoSrc={BulatLogo} headerLinks={headerLinks} additionalBlock={additionalBlock} className=""/>
})