import React, {useContext} from "react";
import "./services-block.scss";
import {Button, EButtonWidth, H3, H4, ModalContext, TextL} from "@mryaros/mryaros-design-system";

export const ServicesBlock = React.memo(function ServicesBlock() {
    return (
        <div className="services-block flex-col-center" id="services">
            <div className="content">
                <H3 className="white"><b>НАШИ УСЛУГИ</b></H3>
                <div className="flex-row flex-j-between flex-wrap margin-all-b-24">
                    {services.map((service) =>
                        <ServiceBlock service={service}/>
                    )}
                </div>
            </div>
        </div>
    );
});

type ServiceBlockProps = {
    service: ServiceBlockType;
};

const ServiceBlock = React.memo(function ServiceBlock({service}: ServiceBlockProps) {
    const {pushModalWindow} = useContext(ModalContext);
    return (
        <div className="service flex-col flex-j-between">
            <H4 className="margin-no">{service.name}</H4>
            <div>
                <TextL className="margin-b-0">{service.priceName1}</TextL>
                <TextL className="margin-no">От <b>{service.price1}</b></TextL>
            </div>
            {service.price2 || service.priceName2 ? (
                <div>
                    {service.priceName2 ? <TextL className="margin-b-0">{service.priceName2}</TextL> : null}
                    {service.price2 ? <TextL className="margin-no">От <b>{service.price2}</b></TextL> : null}
                </div>
            ) : null}
            <Button
                className="margin-t-16"
                label="узнать подробнее"
                buttonWidth={EButtonWidth.FULL_WIDTH}
                onClick={() => pushModalWindow({
                    header: service.name,
                    body: (
                        <>
                            <b>{service.modalPrice1}</b><br />
                            {service.modalPrice2 ? (<b>{service.modalPrice2}</b>) : null}<br /><br />
                            {service.description}
                        </>
                    ),
                    buttons: [],
                })}
            />
        </div>
    );
});

type ServiceBlockType = {
    name: string;
    priceName1: string;
    price1: string;
    priceName2?: string;
    price2?: string;
    modalPrice1: string;
    modalPrice2?: string;
    description: React.ReactNode;
};

const services = [
    {
        name: "Охранная сигнализация",
        priceName1: "Абонентская плата:",
        price1: "1 000 ₽ / мес.",
        priceName2: "Оборудование:",
        price2: "7 000 ₽",
        modalPrice1: "Стоимость обслуживания от 1 000 руб./мес.",
        modalPrice2: "Стоимость оборудования от 7 000 руб.",
        description: <>Обеспечение безопасности при помощи современных систем охранно-пожарной и тревожной сигнализации предполагает, в том числе, последующий выезд ГБР. После поступления сигнала тревоги на наш пульт круглосуточного наблюдения на объект будет отправлена группа быстрого реагирования. Она состоит из сотрудников с удостоверениями 6-го разряда (применение служебного огнестрельного оружия).<br/>Среднее время прибытия группы до 7 минут. При подключении вашего объекта к нашей дежурной части мы проведем бесплатный осмотр объекта, выявим все его уязвимые места и устраним их посредством установки современного оборудования, которое позволит зафиксировать несанкционированное проникновение. Мы применяем как радио-волновую, так и GSM-систему мониторинга, имеем собственный отдел монтажа и обслуживания охранно-пожарной сигнализации, системы контроля доступа, видеонаблюдения.<br />Ежемесячный платеж за услуги охраны:<br />Гаражи - 700 руб<br />Квартиры - 1000 руб.<br />Офисы, магазины - от 1500 руб.<br />Коттеджи, частные дома - от 1500 руб.</>,
    } as ServiceBlockType,
    {
        name: "Пожарная сигнализация",
        priceName1: "Абонентская плата:",
        price1: "1 000 ₽ / мес.",
        priceName2: "Оборудование:",
        price2: "7 000 ₽",
        modalPrice1: "Стоимость обслуживания от 1 000 руб./мес.",
        modalPrice2: "Стоимость оборудования от 7 000 руб.",
        description: <>Обеспечение безопасности при помощи современных систем охранно-пожарной и тревожной сигнализации предполагает, в том числе, последующий выезд ГБР. После поступления сигнала тревоги на наш пульт круглосуточного наблюдения на объект будет отправлена группа быстрого реагирования. Она состоит из сотрудников с удостоверениями 6-го разряда (применение служебного огнестрельного оружия). Среднее время прибытия группы от 5 до 7 минут. Все службы реагирования ЧОО «Булат» оснащены нагрудными видеорегистраторами. При подключении вашего объекта к нашей дежурной части мы проведем бесплатный осмотр объекта, выявим все его уязвимые места и устраним их посредством установки современного оборудования, которое позволит зафиксировать несанкционированное проникновение и предотвратить преступление.<br />Ежемесячный платеж за услуги охраны:<br />Гаражи - 500 руб.<br />Квартиры - 1000 руб.<br />Офисы, магазины - от 1500 руб.<br />Коттеджи, частные дома - от 1500 руб.</>,
    } as ServiceBlockType,
    {
        name: "Кнопка вызова ГБР",
        priceName1: "Абонентская плата:",
        price1: "500 ₽ / мес.",
        priceName2: "Оборудование:",
        price2: "5 000 ₽",
        modalPrice1: "Стоимость обслуживания от 1 000 руб./мес.",
        modalPrice2: "Стоимость оборудования от 7 000 руб.",
        description: <>Кнопка тревожного сигнала (КТС). С ее помощью в случае нападения вы можете самостоятельно подать экстренный сигнал в дежурную часть на пульт центрального наблюдения.<br />Идеально подходит для: офисов, банков, магазинов, кафе, аптек, салонов красоты. Мобильная тревожная кнопка уникальная услуга в сфере личной безопасности.<br/>Также подходит для: квартир, загородных домов, дач.</>,
    } as ServiceBlockType,
    {
        name: "Инкассация",
        priceName1: "Автомобиль ЧОО «Булат»:",
        price1: "150 ₽ / час.",
        priceName2: "Автомобиль заказчика:",
        price2: "180 ₽ / час.",
        modalPrice1: "Стоимость услуги от 700 руб./час",
        description: "Сотрудники ЧОО «Булат» регулярно сопровождают кассиров и инкассаторов по всей России. Данная услуга подразумевает перевозку опасных и ценных грузов. Безусловно, ликвидации рисков требуется помощь профессионалов. Сотрудники охранной организации ЧОО «Булат» не только сопровождают груз до места назначения, но и разрабатывают маршрут, производят его корректировку, моделирование возможных угроз. Сотрудники ЧОО «Булат» имеют необходимый набор профессиональных навыков, а также снарежены огнестрельным оружием и средствами защиты. Под бдительным контролем наших сотрудников ваш груз прибудет в точку назначения в полном порядке и точно в срок.",
    } as ServiceBlockType,
    {
        name: "Сопровождение груза",
        priceName1: "Посуточная защита:",
        price1: "300 ₽ / час",
        priceName2: "Почасовая защита:",
        price2: "400 ₽ / час",
        modalPrice1: "Стоимость услуги от 300 руб./час",
        description: "Перевозка грузов, в частности, особо ценных связана с определенным набором рисков. Безусловно, для их ликвидации требуется помощь профессионалов. Сотрудники охранной организации ЧОО «Булат» не только сопровождают груз до места назначения, но и разрабатывают маршрут, производят его корректировку, моделирование возможных угроз. Сотрудники ЧОО «Булат» имеют необходимый набор профессиональных навыков, а также снарежены огнестрельным оружием и средствами защиты. Под бдительным контролем наших сотрудников Ваш груз прибудет в точку назначения в полном порядке и точно в срок.",
    } as ServiceBlockType,
    {
        name: "КТС + охранная сигнализация",
        priceName1: "Абонентская плата:",
        price1: "1 000 ₽ / мес.",
        priceName2: "Оборудование:",
        price2: "8 000 ₽ ",
        modalPrice1: "Стоимость обслуживания от 1 000 руб./мес.",
        modalPrice2: "Стоимость оборудования от 8 000 руб.",
        description: <>Кнопка тревожного сигнала (КТС). С ее помощью в случае нападения вы можете самостоятельно подать экстренный сигнал в дежурную часть на пульт центрального наблюдения.<br />Идеально подходит для: офисов, банков, магазинов, кафе, аптек, салонов красоты. Мобильная тревожная кнопка уникальная услуга в сфере личной безопасности.<br />Также подходит для: квартир, загородных домов, дач.</>,
    } as ServiceBlockType,
    {
        name: "КТС + охранно-пожарная сигнализация",
        priceName1: "Абонентская плата:",
        price1: "2 000 ₽ / мес.",
        priceName2: "Оборудование:",
        price2: "10 000 ₽ ",
        modalPrice1: "Стоимость обслуживания от 2 000 руб./мес.",
        modalPrice2: "Стоимость оборудования от 10 000 руб.",
        description: <>Кнопка тревожного сигнала (КТС). С ее помощью в случае нападения вы можете самостоятельно подать экстренный сигнал в дежурную часть на пульт центрального наблюдения.<br />Идеально подходит для: офисов, банков, магазинов, кафе, аптек, салонов красоты. Мобильная тревожная кнопка уникальная услуга в сфере личной безопасности.<br />Также подходит для: квартир, загородных домов, дач.</>,
    } as ServiceBlockType,
    {
        name: "Мобильный телохранитель",
        priceName1: "Оплата:",
        price1: "2 000 ₽ / мес.",
        priceName2: "*Оборудование: телефон заказчика",
        modalPrice1: "Стоимость услуги 2 000 руб./мес.",
        description: <>Современная технология «Мобильный телохранитель», тревожная кнопка, которая всегда с вами, в вашем кармане. Не требует сложной установки и технического обслуживания, не заметен и удобен в использовании в случае опасности. Система представляет из себя приложение, в котором имеется кнопка по тревоге, при нажатии кнопки на мобильном телефоне, на вызов выезжает группа быстрого реагирования ЧОО «Булат». При нахождении абонента в другом регионе будут предприняты меры для направления ГБР наших партнеров.<br/>Подходит для: квартир, дач, коттеджей, офисов, магазинов и индивидуальной защиты.</>,
    } as ServiceBlockType,
    {
        name: "Мониторинг объекта",
        priceName1: "Абонентская плата:",
        price1: "500 ₽ / мес.",
        priceName2: "Оборудование:",
        price2: "7 000 ₽ ",
        modalPrice1: "Стоимость услуги от 500 руб.",
        description: "Используем лучшее программное обеспечение по приему и обработке тревог. Операторы центра мониторинга обрабатывают сигналы тревог и мгновенно отправляют ближайшую группу быстрого реагирования на объект. Благодаря собственному мониторинговому центру, исключены сбои и задержки сигналов тревог.",
    } as ServiceBlockType,
    {
        name: "Монтаж оборудования",
        priceName1: "Оплата:",
        price1: "500 ₽",
        priceName2: "*Переключение с другого ЧОПа бесплатно",
        modalPrice1: "Стоимость услуги от 500 руб.",
        description: "Установим все необходимое оборудование в офисе, доме, квартире, на предприятии, чтобы исключить риск воровства на рабочем месте и проникновения посторонних лиц.",
    } as ServiceBlockType,
    {
        name: "Личная охрана",
        priceName1: "Посуточная защита:",
        price1: "5 000 ₽ / сутки",
        priceName2: "Длительная защита:",
        price2: "80 000 ₽ / мес. ",
        modalPrice1: "Стоимость услуги от 5 000 руб./сутки",
        description: <>Охрана VIP-персон предполагает повышенный уровень требований к сотрудникам, обеспечивающим безопасность медийных личностей, деятелей политики, бизнесменов. В ЧОО «Булат» услугу личной охраны может заказать любой человек. Охрана во время деловых встреч, отдыха, переездов по городу, трансфер с аэропорта, ЖД.<br />Имеем собственный парк автомобилей<br />Круглосуточная охрана на временной или постоянной основе (24/7)<br />Все нюансы прописываются в договоре, стоимость услуг фиксирована и не изменяется с течением времени.</>,
    } as ServiceBlockType,
    {
        name: "Видеонаблюдение",
        priceName1: "Абонентская плата:",
        price1: "500 ₽ / мес.",
        priceName2: "Оборудование:",
        price2: "7 000 ₽ ",
        modalPrice1: "Стоимость обслуживания от 500 руб./мес.",
        modalPrice2: "Стоимость оборудования от 7 000 руб.",
        description: "Профессионально осуществляем монтаж и обслуживание систем видеонаблюдения. С таким оборудованием вы поднимете свою безопасность на новый уровень, сможете избежать множество возможных угроз и просто будете чувствовать себя более спокойно. Современные системы видеонаблюдения позволяют контролировать передвижения людей и транспорта по территории объекта, сигнализировать о не прошенных гостях. Их возможно интегрировать в единую систему с охранной сигнализацией.",
    } as ServiceBlockType,
    {
        name: "СКУД",
        priceName1: "Абонентская плата:",
        price1: "500 ₽ / мес.",
        priceName2: "Оборудование:",
        price2: "5 000 ₽ ",
        modalPrice1: "Стоимость обслуживания от 500 руб./мес.",
        modalPrice2: "Стоимость оборудования от 5 000 руб.",
        description: "СКУД – это система контроля и управления доступом, которая устанавливается для ограничения доступа, например, в торговый центр, магазин, склад. Вы получите контроль над сотрудниками, их рабочим временем и повысите эффективность предприятия.  Объединив СКУД с видеонаблюдением, вы сможете управлять безопасностью вашей компании или дома, даже пока находитесь в отпуске на другом конце мира. Также вы можете установить шлагбаумы с распознаванием гос. номеров, чтобы обезопасить территорию своего объекта от въезда посторонних лиц.",
    } as ServiceBlockType,
    {
        name: "Охрана массовых мероприятий",
        priceName1: "Оплата:",
        price1: "600 ₽",
        modalPrice1: "Стоимость обслуживания от 500 руб./час",
        description: <>Охрана мероприятий во время выступления музыкальных групп, спортивные состязания, конференции, митинги или коктейльные вечеринки – все это относится к массовым мероприятиям. Любое массовое мероприятие - это собрание людей, которое не исключает угрозу безопасности гостей и участников.<br/>ЧОО «Булат» обеспечит охрану общественного порядка при проведении мероприятий любого масштаба. Наша компания имеет большой опыт в выполнении таких задач. Обеспечить безопасность людей на массовых мероприятиях – это наша основная задача, которую мы выполняем качественно.</>,
    } as ServiceBlockType,

];